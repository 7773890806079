<template>
  <el-dialog title="编辑三维资源" :fullscreen="true" :modal-append-to-body="true"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             v-if="visible"
             :before-close="handleClose">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="资源信息" name="0">
        <el-row>
          <el-col :span="14" class="b_d p_all2">
            <el-row :gutter="10" class="m_b2">
              <el-col :span="7">
                <el-input size="small" v-model="fileName" @keyup.enter.native="refreshList(1)" placeholder="请输入文件名称" clearable></el-input>
              </el-col>
              <el-col :span="4">
                <el-select v-model="levelId" clearable placeholder="请选择" size="small"
                           @keyup.enter.native="refreshList(1)"
                           @change="queryFileList">
                  <el-option :label="item.fieldName" :value="item.id" v-for="(item, index) in bizList"
                             :key="index"></el-option>
                </el-select>
              </el-col>
              <el-col :span="6">
                <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">
                  查询
                </el-button>
                <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
              </el-col>
            </el-row>
            <el-table :data="dataList" v-loading="loading" size="small" row-key="id"
                      height="calc(100vh - 380px)"
                      :header-cell-style="{ 'text-align': 'left' }" :cell-style="{ 'text-align': 'left' }"
                      default-expand-all
                      class="table treeTb" :tree-props="{ children: 'fileVoList' }" @row-click="clickData">
              <el-table-column class="treeTd" prop="fileName" show-overflow-tooltip sortable label="文件名称">
              </el-table-column>
              <el-table-column prop="modelType" show-overflow-tooltip sortable label="数据分级">
                <template slot-scope="scope">
                  <span>{{ formatField(scope.row.modelType) }}</span>
                </template>
              </el-table-column>

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button size="small"
                             v-if="scope.row.fileVoList
                      && (scope.row.fileVoList[0].onShow == null
                        || scope.row.fileVoList[0].onShow != '0') && hasPermission('resource:list:setMaster')"
                             type="text" @click="epiphany(scope.row.fileVoList, 0)">设为主显
                  </el-button>
                  <el-button size="small"
                             v-if="scope.row.fileVoList
                      && (scope.row.fileVoList[0].onShow != null
                        && scope.row.fileVoList[0].onShow == '0') && hasPermission('resource:list:delMaster')"
                             type="text" @click="epiphany(scope.row.fileVoList, 1)">删除主显
                  </el-button>
                  <el-button v-if="scope.row.fileVoList && hasPermission('resource:details:delFile')"
                             size="small"
                             type="text"
                             @click="del(null, 1, scope.row.folderOnlyId, scope.row.fileVoList)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                           :current-page="pageNo"
                           :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
          </el-col>
          <el-col :span="10">
            <el-tabs type="border-card" class="ajodfjkadbf">
              <el-tab-pane label="文件详情" v-if="!(inputForm.id == null || inputForm.id == '')">
                <el-form size="small" :model="inputForm" ref="inputForm" label-width="100px">
                  <el-form-item label="文件名称：" prop="fileName">
                    <el-input v-model="inputForm.fileName" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="文件描述：" prop="fileDesc">
                    <el-input v-model.trim="inputForm.fileDesc" placeholder="限20字" maxlength="20"
                              clearable></el-input>
                  </el-form-item>
                  <el-form-item label="文件大小：" prop="fileSizeFormat">
                    <el-input v-model="inputForm.fileSizeFormat" disabled></el-input>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="资源详情">
                <el-form size="small" :model="detailsForm" ref="detailsForm" label-width="120px">
                  <el-form-item label="缩略图：" prop="thumbPath" :rules="[
            { required: true, message: '请上传缩略图', trigger: 'change' }
          ]">
                    <div class="flex_l_t">
                      <div
                          v-for="(item,index) in upDateList" :key="index"
                          style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
                      >
                        <div class="el-icon-error" @click="deleteFilePhoto(item,index)"
                             style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
                        <el-image
                            style="width: 100%; height: 110px;"
                            :src="item.netUrl"
                            :fit="'cover'"
                            :preview-src-list="[item.netUrl]"
                        >
                          <div slot="error" class="image-slot">
                            <el-image
                                style="width: 100%; height: 110px;"
                                :src="require('@/assets/img/default.png')"
                                :fit="'cover'">
                            </el-image>
                          </div>
                        </el-image>
                        <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                          <div style="font-size: 12px;width: 100%;margin-top: -18px;" class="omit">{{
                              item.fileName
                            }}
                          </div>
                        </el-tooltip>
                      </div>
                      <upload v-show="upDateList.length == 0" :uploadAskObj="uploadRequire2" @getDataList="getDataList">
                        <template v-slot:updateStyle>
                          <div style="width: 100px;
                          text-align: center;
                          height: 100px;
                          line-height: 100px;
                          border: 1px dashed #d9d9d9;
                          border-radius: 6px;
                          ">
                            <div class="el-icon-plus" style="
                            font-size: 20px;
                              "></div>
                          </div>
                        </template>
                      </upload>
                    </div>
                  </el-form-item>
                  <el-form-item label="资源类型：">三维</el-form-item>
                  <el-form-item label="类型编码：">{{ detailsForm.resourceTypeCode }}</el-form-item>
                  <el-form-item label="资源简称：" prop="resourceFoshot">
                    <el-input v-model.trim="detailsForm.resourceFoshot" maxlength="100"
                              placeholder="请输入资源简称（100字内）" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="是否藏品：" prop="onCollection">
                    <el-switch v-model="detailsForm.onCollection" :active-value="1" disabled
                               :inactive-value="0" on-value="1"
                               off-value="0"></el-switch>
                  </el-form-item>
                  <el-form-item label="资源描述：" prop="resourceDesc">
                    <el-input type="textarea" placeholder="请输入资源描述（100字内）"
                              v-model.trim="detailsForm.resourceDesc" maxlength="100"
                              :autosize="{ minRows: 3, maxRows: 6 }"></el-input>
                  </el-form-item>
                  <el-form-item label="资源名称：" prop="resourceName"
                                :rules="[{ required: true, message: '请输入资源名称', trigger: 'change' }]">
                    <el-input v-model.trim="detailsForm.resourceName" maxlength="100"
                              placeholder="请输入资源名称（100字内）"></el-input>
                  </el-form-item>
                  <el-form-item v-for="(item, index) in dynamicForm" :key="index"
                                :label="`${item.fieldName}：`"
                                :prop="item.fieldEname">
                    <el-date-picker
                        v-if="item.detailsShowType == 3"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        v-model="detailsForm[item.fieldEname]"
                        type="date"
                        placeholder="选择日期" class="w100i">
                    </el-date-picker>
                    <el-input v-else-if="item.fieldEname == 'resourceTitle'"
                              v-model.trim="detailsForm[item.fieldEname]" maxlength="500"
                              placeholder="请输入（限500字）"></el-input>
                    <el-input v-model="detailsForm[item.fieldEname]" maxlength="20"
                              placeholder="请输入（限20字）" clearable v-else></el-input>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="关联藏品" name="1">
        <div class="flex_l_c">
          <!--          <span class="m_r1 f_w">藏品编号</span>-->
          <!--          <el-input class="m_r1" size="small" v-model="collectionNum" placeholder="请输入藏品编号" clearable-->
          <!--            style="width: 30%;"></el-input>-->
          <el-button type="primary" @click="relatedCollections" :disabled="collectioList.length != 0"
                     style="margin-bottom: 10px" size="small">获取藏品
          </el-button>
        </div>
        <!--        <div :span="24" v-if="collectionShow">-->
        <!--          <div class="b_d p_all1 m_t1 flex_b_c" v-for="(item, index) in searchCollectioList" :key="index">-->
        <!--            <span class="p_r2"><span class="f_w">藏品名称：</span>{{ item.collection_name }}</span>-->
        <!--            <span class="p_r2"><span class="f_w">藏品级别：</span>{{ item.collection_level }}</span>-->
        <!--            <span class="p_r2"><span class="f_w">藏品分类：</span>{{ item.collection_type }}</span>-->
        <!--            <span class="p_r2"><span class="f_w">藏品质地：</span>{{ item.collection_texture }}</span>-->
        <!--            <span class="p_r2"><span class="f_w">藏品年代：</span>{{ item.collection_years }}</span>-->
        <!--            <el-button size="mini" type="primary" v-show="hasPermission('admin:resources:relatedCollections')"-->
        <!--              @click="link(item.id)">关联</el-button>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="flex_l_c p_t15">
          <div :style="{ background: defaultTheme }" class="titLine"></div>
          <div class="f_w">已关联藏品</div>
        </div>
        <el-table :data="collectioList" v-loading="loading" size="small" height="calc(100vh - 260px)"
                  class="table">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <!--              <el-button size="mini" v-if="scope.row.onLink != 0" type="danger" plain @click="link(scope.row.id)">关联-->
              <!--              </el-button>-->
              <el-button size="small" type="text"
                         v-show="hasPermission('admin:resources:cancelCollection')"
                         @click="del(scope.row.id, 2)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="资源日志" name="2">
        <el-row :gutter="10" class="m_b2">
          <el-col :span="7">
            <el-input size="small" v-model="logSrh" @keyup.enter.native="refreshLogList(1)" placeholder="请输入文件名称关键词" clearable></el-input>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" @click="refreshLogList(1)" size="small" icon="el-icon-search">查询
            </el-button>
            <el-button @click="resetLogList()" size="small" icon="el-icon-refresh-right">重置</el-button>
          </el-col>
        </el-row>
        <el-table :data="logList" v-loading="loading" size="small" height="calc(100vh - 300px)" class="table">
          <el-table-column prop="fileName" show-overflow-tooltip sortable label="文件名">
          </el-table-column>
          <el-table-column prop="operationType" show-overflow-tooltip sortable label="操作类型">
          </el-table-column>

          <el-table-column prop="operationRes" show-overflow-tooltip sortable label="操作结果">
          </el-table-column>
          <el-table-column prop="updateUserName" show-overflow-tooltip sortable label="更新人">
          </el-table-column>
          <el-table-column prop="updateDate" show-overflow-tooltip sortable label="更新时间">
          </el-table-column>
          <el-table-column prop="updateIp" show-overflow-tooltip sortable label="更新人IP">
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandleLog" @current-change="currentChangeHandleLog"
                       :current-page.sync="pageNoLog" :page-sizes="[10, 20, 50, 100]" :page-size="pageSizeLog"
                       :total="totalLog"
                       background layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="资源上传" name="3">
        <div class="page">
          <uploader v-if="showUploader" :browse_button="idArr"
                    :url="this.$globalUrl() + 'zyd-zgww/commonFile/uploadThree'" chunk_size="10MB"
                    :multi_selection="true"
                    :max_retries="3" :filters="myfilters" :FilesAdded="filesAdded"
                    :BeforeUpload="beforeUpload1" :Error="error"
                    :headers="myHeaders" :UploadComplete="uploadComplete" :FileUploaded="fileUploaded"
                    :UploadProgress="uploadProgress" @inputUploader="inputUploader"/>
          <div class="flex_c_c">
            <div class="text_center uploadBtn" v-for="(item, index) in bizList" :key="index"
                 @click="upband(item.id)"
                 :id="item.id">
              <div>上传{{ item.fieldName }}文件</div>
              <div>（仅支持{{ allowFiles }}压缩文件）</div>
            </div>
            <!--                        <el-button size="small" type="primary" v-for="(item, index) in bizList" :key="index" @click="upband(item.id)" :id="item.id">上传{{item.fieldName}}文件</el-button>-->
          </div>
          <div class="text_center m_t2" v-if="files.length == 0">
            <img src="../../../../../../assets/img/upload.png">
          </div>
          <div class="contentBox">
            <div class="flex_l_c item" v-for="(item, index) in files" :key="index">
              <el-button class="delBtn" icon="el-icon-close" @click="deleteFile(item.id, index)"
                         type="primary" size="mini"
                         circle></el-button>
              <div class="uploadImg flex_c_c">
                <img src="../../../../../../assets/img/Scheme.png" v-if="item.name.substring(item.type.indexOf('.') + 1) == 'png'
                  || item.name.substring(item.type.indexOf('.') + 1) == 'jpg'
                  || item.name.substring(item.type.indexOf('.') + 1) == 'gif'
                  ">
                <img src="../../../../../../assets/img/zipIcon.png" v-else-if="item.name.substring(item.type.indexOf('.') + 1) == 'zip'
                  || item.name.substring(item.type.indexOf('.') + 1) == 'rar'
                  ">
                <img src="../../../../../../assets/img/xlsIcon.png" v-else-if="item.name.substring(item.type.indexOf('.') + 1) == 'xls'
                  || item.name.substring(item.type.indexOf('.') + 1) == 'xlsx'
                  ">
                <img src="../../../../../../assets/img/word.png" v-else-if="item.name.substring(item.type.indexOf('.') + 1) == 'doc'
                  || item.name.substring(item.type.indexOf('.') + 1) == 'docx'
                  ">
                <img src="../../../../../../assets/img/document.png" v-else>
              </div>
              <div class="flex1 p_r2">
                <el-row>
                  <el-col :span="8" class="omit">{{ item.name }}</el-col>
                  <el-col :span="3" class="text_right"
                          v-if="item.status === 2 || item.status === 1 && item.percent > 0">
                    <el-button style="padding: 0" type="text" v-if="!uploading"
                               icon="el-icon-video-play"
                               @click="uploadStart()"></el-button>
                    <el-button style="padding: 0" type="text" icon="el-icon-video-pause"
                               @click="uploadStop()"
                               v-else></el-button>
                  </el-col>
                  <el-col :span="2" class="text_left"
                          v-if="item.status === 2 || item.status === 1 && item.percent > 0">{{ rate }}
                  </el-col>
                  <el-col :span="3" class="text_center">
                    <span v-if="item.status === -1">正在计算MD5</span>
                    <span v-if="item.status === 1 && item.percent === 0">等待上传</span>
                    <span v-if="item.status === 4" style="color: brown">上传失败</span>
                    <span v-if="item.status === 5" style="color: #009900">上传成功</span>
                  </el-col>
                </el-row>
                <el-progress class="m_t1"
                             v-if="item.status === 2 && !percentflag || item.status === 1 && item.percent > 0 && !percentflag"
                             :text-inside="true" :stroke-width="15"
                             :percentage="item.percent"></el-progress>
                <el-progress class="m_t1"
                             v-if="item.status === 2 && percentflag || item.status === 1 && percentflag && item.type.substring(item.type.indexOf('|') + 1) > 0"
                             :text-inside="true" :stroke-width="15"
                             :percentage="percentUp"></el-progress>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <span v-if="activeName == 0" slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" v-show="hasPermission('admin:resources:resourceEdit')" @click="doSubmit()"
                 v-noMoreClick>保存</el-button>
    </span>
    <coll-list ref="collectionList"
               @seltDataColl="linkCollection"></coll-list>
  </el-dialog>
</template>

<script>
import FileMd5 from '@/utils/file-md5'
import Uploader from "@/components/Upload/Uploader";
import Upload from "@/components/Upload/Upload";
import CollList from "@/views/modules/dyyg/gather/resource/collList.vue";
import resumeUpload from "@/components/Upload/resumeUpload.vue"; // 导入上传组件

export default {
  components: {resumeUpload, CollList, Uploader, Upload},
  data() {
    return {
      visible: false,
      activeName: '0',
      // 资源信息
      defaultProps: {
        children: 'fileVoList',
        label: 'fileName'
      },
      dataList: [],

      fileName: '',
      pageNo: 0,
      pageSize: 10,
      total: 0,
      loading: false,
      inputForm: {},
      detailsForm: {},
      bizList: [],
      collectionMap: {
        id: "",
        collectionName: "",
        collectionLevel: "",
        collectionType: "",
        collectionTexture: "",
        collectionYears: ""
      },
      // 关联藏品
      collectionNum: '',
      collectionShow: false,
      collectioList: [],
      // 资源日志
      logSrh: '',
      logList: [],
      pageNoLog: 0,
      pageSizeLog: 10,
      totalLog: 0,
      resourceId: "",
      dynamicForm: [],
      searchCollectioList: [],
      levelId: null,
      // 资源上传
      showUploader: false,
      uploadRequire: {},
      idArr: ['955785177131384832', '955785211726004224', '955785249650900992'],
      myfilters: {
        prevent_duplicates: true //不允许选取重复文件},
      },
      myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
      tableData: [],
      eDirectoryName: "",
      rate: "",
      uploading: false,
      up: {},
      files: [],
      percent: 0,
      percentflag: false,
      percentUp: 0,
      eleId: '',
      ingFileLen: 0,
      overFileLen: 0,
      // 数组为允许上传的文件
      allowFiles: ['stl', 'zip', '7z', 'rar'],
      leaveList: [],

      // 缩略图上传要求
      uploadRequire2: {
        list:[],
        showFile: false, // 是否显示已上传文件列表
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['jpg', 'png', 'jpeg'],
        megaNum: 10,
        showTips: true,
        customUploadStyle: true,
      },
      upDateList: [],
    }
  },
  computed: {
    // 主题色
    defaultTheme() {
      return this.$store.state.config.defaultTheme
    },
  },
  mounted() {
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
  },
  methods: {
    //关联藏品
    relatedCollections() {
      this.$refs.collectionList.int(1, {resourceId: this.resourceId, oper: 1})
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    // 获取上传缩略图数据
    getDataList(data) {
      this.upDateList = [
        {
          netUrl:data.response.data.url,
          fileName:data.response.data.name,
        }
      ]
      this.detailsForm.fileName = data.response.data.name
      this.detailsForm.thumbPath = data.response.data.path
      // this.detailsForm.url = data.response.data.url
    },

    deleteFilePhoto(row, index) {
      this.$confirm('此操作将删除该缩略图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.upDateList.splice(index, 1)
        this.uploadRequire2.list = []
        this.detailsForm.fileName = ''
        // this.detailsForm.url = ''
        this.detailsForm.thumbPath = ''
      })
    },

    handleNodeClick(data) {
    },


    init(row) {
      this.resourceId = row.id
      this.fileName = '';
      this.collectionNum = ''
      this.logSrh = ''
      this.bizList = []
      this.collectionShow = false
      this.visible = true
      this.$nextTick(() => {
        this.activeName = '0';
        this.showUploader = false;
      })
      this.refreshList()
      this.getFieldByResourceName()
      this.getDynamicForm()
      this.getResourceId()
      this.linkCollectionList(1)
      this.refreshLogList()
    },
    handleClick(tab, event) {
      if (tab.index == 3) {
        this.showUploader = true
      }
    },
    handleClose() {
      this.collectionNum = '';
      this.visible = false
      this.$emit('refreshDataList')
    },
    queryFileList(levelId) {
      this.levelId = levelId
    },
    // 资源信息列表数据查询
    refreshList(type) {
      if (type == 1) {
        this.pageNo = 1
      }
      let parma = {
        resourceId: this.resourceId,
        current: this.pageNo,
        size: this.pageSize,
        filename: this.fileName,
        levelId: this.levelId
      }

      this.$axios(this.api.dyyg.listFile, parma, 'get').then(data => {
        if (data && data.status) {
          this.dataList = data.data.records
          this.total = parseInt(data.data.total)
          this.dataList.forEach(item => {
            this.$set(item, 'fileName', item.folderOnlyName)
            this.$set(item, 'id', item.folderOnlyId)
          })
          if (this.dataList.length > 0) {
            this.inputForm = this.dataList[0].fileVoList[0]
          }
        }
      })
    },
    formatField(modelType) {
      for (let index = 0; index < this.bizList.length; index++) {
        const element = this.bizList[index];
        if (element.id == modelType) {
          return element.fieldName
        }
      }
    },
    // 资源文件查询
    listFile() {
      var that = this;
      let parma = {}
      that.$axios(this.api.dyyg.listFile, '', "get").then((res) => {
        if (res.status) {
          that.columnList = res.data
        }
      })
    },
    // 获取资源信息
    getResourceId() {
      var that = this;
      that.$axios(this.api.dyyg.getResourceId + this.resourceId, "", "get").then((res) => {
        if (res.status) {
          that.detailsForm = res.data
          that.upDateList = [];
          if (this.detailsForm.url) {
            this.upDateList.push(
                {
                  fileName: this.detailsForm.fileName,
                  filePath: this.detailsForm.thumbPath,
                  netUrl: this.detailsForm.url,
                },
            )
          }
        }
      })
    },

    // 获取业务字段
    getFieldByResourceName() {
      var that = this;
      let parma = {
        resourceName: "三维资源/数据匹配"
      }
      that.$axios(this.api.dyyg.getFieldByResourceName, parma, "get").then((res) => {
        if (res.status) {
          that.bizList.push(...res.data);
        }
      })
    },

    // 藏品查询
    linkCollectionList(type) {
      var that = this;
      let parma = {
        resourceId: that.resourceId,
        num: that.collectionNum,
        type: type
      }
      that.$axios(this.api.dyyg.linkCollectionList, parma, "get").then((res) => {
        if (res.status) {
          if (type == 0) {
            if (res.data.length == 0) {
              this.$message('暂无相关藏品信息')
            } else {
              this.collectionShow = true;
            }
            that.searchCollectioList = res.data

          } else {
            that.collectioList = res.data
          }

        }
      })
    },

    // 关联藏品
    linkCollection(row) {
      this.collectioList = [row]
    },

    getDynamicForm() {
      var that = this;
      let parma = {
        masterTableId: "946049961995272192",
        pageType: 1
      }
      that.$axios(this.api.dyyg.getTableByType, parma, "get").then((res) => {
        if (res.status) {
          that.dynamicForm = res.data
          that.dynamicForm.forEach(function (v) {
            v.fieldEname = that.toHump(v.fieldEname)
          })
          // console.log("配置：",this.dynamicForm);
        }
      })
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    // 点击资源信息行
    clickData(row) {
      // 父级拒绝点击
      if (row.fileVoList != null) {
        return
      }
      this.inputForm = row
    },
    // 设为主显
    epiphany(fileVoList, state) {
      this.$confirm(`确定${state == 1 ? '删除' : '设为'}主显吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let fileIds = [] = fileVoList.map(item => {
          return item.id
        })
        let param = {
          fileIds: fileIds,
          state: state
        }
        this.$axios(this.api.dyyg.setFileMaster, param, 'post').then(data => {
          if (data && data.status) {
            this.$message.success('操作成功')
            this.refreshList()
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    // 删除 type: 1资源信息删除，2关联藏品删除
    del(id, type, folderOnlyId, fileVoList) {
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (type == 1) { //资源信息删除
          let fileIds = [] = fileVoList.map(item => {
            return item.id
          })
          let param = {
            fileIds: fileIds,
            folderOnlyId: folderOnlyId,
          }
          this.$axios(this.api.dyyg.delEntityFile, param, 'post').then(data => {
            if (data && data.status) {
              this.$message.success("删除成功")
              this.refreshList()
              this.refreshLogList()
            }
          })

        } else if (type == 2) { //关联藏品删除
          this.collectioList = []
          // let param = {
          //     resourceId: this.resourceId,
          //     collectionId: id,
          //     oper: 0
          // }
          //
          // this.$axios(this.api.dyyg.linkOrDelCollection, param, 'get').then(data => {
          //     if (data && data.status) {
          //         this.$message.success("删除成功")
          //         this.linkCollection()
          //     }
          // })
        }
      })
    },
    link(id) {
      let param = {
        resourceId: this.resourceId,
        collectionId: id,
        oper: 1
      }

      this.$axios(this.api.dyyg.linkOrDelCollection, param, 'get').then(data => {
        if (data && data.status) {
          this.$message.success("关联成功")
          this.linkCollection()
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 重置
    reset() {
      this.levelId = null
      this.fileName = ''
      this.refreshList(1)
    },
    // 资源信息每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 资源信息当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },

    // 资源日志列表数据查询
    refreshLogList(type) {
      if (type == 1) {
        this.pageNoLog = 1
      }
      let param = {
        resourceId: this.resourceId,
        current: this.pageNoLog,
        size: this.pageSizeLog,
        fileName: this.logSrh
      }
      this.$axios(this.api.dyyg.getfileRecode, param, 'get').then(data => {
        if (data && data.status) {
          this.logList = data.data.records
          this.totalLog = parseInt(data.data.total)
        }
      })

    },
    resetLogList() {
      this.logSrh = ''
      this.refreshLogList(1)
    },
    // 资源信息每页数
    sizeChangeHandleLog(val) {
      this.pageSizeLog = val
      this.pageNoLog = 1
      this.refreshLogList()
    },
    // 资源信息当前页
    currentChangeHandleLog(val) {
      this.pageNoLog = val
      this.refreshLogList()
    },
    // 保存
    doSubmit() {
      this.$refs.detailsForm.validate((valid) => {
        if (valid) {
          if (this.activeName == 3) {
            this.overFileLen = 0
            this.ingFileLen = 0
            if (this.files.length == 0) {
              this.$message('请上传文件');
              return
            }
            this.files.forEach(item => {
              if (item.status == 5) {
                this.overFileLen++
              } else if (item.status != 5 || item.status != 4) {
                this.ingFileLen++
              }
            })
            if (this.ingFileLen > 0) {
              this.$message('请等待上传文件完毕');
              return
            }
          }
          if (this.detailsForm.resourceName.length == 0 || this.detailsForm.resourceName.split(" ").join("").length == 0) {
            this.$message.warning("请输入资源名称")
            return
          }
          if (!this.collectioList.length) {
            this.$message.warning("请关联藏品")
            return
          }
          this.$set(this.collectioList[0], 'collectionId', this.collectioList[0].id)
          this.$delete(this.collectioList[0], 'id')
          let param = {
            resources: {
              ...this.detailsForm,
              ...this.collectioList[0],
            },
            resourceFileEntity: this.inputForm
          }
          this.$axios(this.api.dyyg.saveResourceAndFile, param, 'post').then(data => {
            if (data && data.status) {
              this.$message.success("保存成功")
              this.refreshList(null)
            } else {
              this.$message.error("保存失败")
            }
          })
        }
      })
    },
    // 资源上传
    filesAdded(up, files) {
      this.percent = 0;
      files.forEach((f) => {
        f.levelType = this.eleId

        let filename = f.name
        var suffix = filename.substring(filename.lastIndexOf(".") + 1);
        var suf = suffix.toLowerCase()
        if (this.allowFiles.indexOf(suf) == -1) {
          this.$message.error("请上传" + this.allowFiles.toString() + "格式文件！");
          f.status = 4;
          return;
        }


        f.status = -1;
        // f.type =this.eDirectoryName+"|0";
        this.tableData.push({
          eDirectoryName: this.eDirectoryName
        })
        FileMd5(f.getNative(), (e, md5) => {
          f["md5"] = md5;
          f.status = 1;
          this.$axios(this.api.dyyg.getPercentage, {md5: f.md5, size: f.size}, 'get').then(res => {
            if (res.status) {
              if (res.data != 0) {
              }
              this.files.forEach((f) => {
                if (f.status != 2) {
                  this.uploadStart();
                  this.uploading = true;
                }
              })
            }
          });
        });

      });


      // this.$refs['begin'];
      // this.$refs.begin.dispatchEvent(new MouseEvent('click'));
      // this.$emit("uploadStop","")
      // let aa = setTimeout(()=>{
      //     this.uploadStart();
      // },100)


    },
    beforeUpload1(up, file) {
      up.settings.url = this.$globalUrl() + 'zyd-zgww/commonFile/uploadThree' + '?fileLevelId=' + file.levelType + "&resourceId=" + this.resourceId
      up.setOption("multipart_params", {
        "size": file.size,
        "md5": file.md5,
        "uploadPath": this.uploadRequire.uploadPath
      });
    },
    error(uploader, errObject) {
      this.uploading = false;
      switch (errObject.code) {
        case -100:
          this.$message.error("上传失败");
          break;
        case -200:
          this.$message.error("http网络错误");
          break;
        case -300:
          this.$message.error("发生磁盘读写错误时的错误代码，例如本地上某个文件不可读");
          break;
        case -400:
          this.$message.error("发生因为安全问题而产生的错误");
          break;
        case -500:
          this.$message.error("初始化时发生错误");
          break;
        case -600:
          this.$message.error("选择的文件太大");
          break;
        case -601:
          this.$message.error("选择的文件类型不符合要求");
          break;
        case -602:
          this.$message.error("选取了重复的文件");
          break;
        case -700:
          this.$message.error("图片格式错误");
          break;
        case -702:
          this.$message.error("文件大小超过系统所能处理的最大值");
          break;
        default:
          this.$message.error("内存错误");
      }
      // this.$message.error(errObject.message);
      // this.$message.error(`上传文件只能是${this.myfilters.mime_types[0].extensions}格式!`);
    },
    uploadComplete() {
      this.uploading = false;
    },
    fileUploaded(uploader, file, responseObject) {
      let data = JSON.parse(responseObject.response)
      if (data.code == 500) {
        if (data.msg == null || data.msg == '') {
          this.$message.error('系统异常，请稍后再试');
        } else {
          this.$message.error(data.msg);
        }
        this.files.map(item => {
          if (item.id == file.id) {
            item.status = 4
          }
        })
        return
      }
      if (data.status) {
        //日志记录添加
        this.$axios(this.api.dyyg.saveFileLogList, {
          'fileIds': data.data.fileIds,
          'operationType': '',
          'resourceId': '',
          'fileName': '',
        }, 'post').then(data => {
          this.refreshLogList(1)
        })
      }
      if (this.files !== undefined && this.files.length > 0) {
        this.percentflag = false;
        this.percentUp = 0;
        this.rate = "0.00B/s";
        file.fileLevelId = data.data.fileLevelId
        file.paths = data.data.path
        this.refreshList(this.levelId)
      }
    },
    uploadProgress(uploader, file) {
      this.bytesPerSec = this.up.total.bytesPerSec
      if (this.bytesPerSec < 0.1 * 1024) {                            //小于0.1KB，则转化成B
        this.rate = this.bytesPerSec.toFixed(2) + "B/s"
      } else if (this.bytesPerSec < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
        this.rate = (this.bytesPerSec / 1024).toFixed(2) + "KB/s"
      } else if (this.bytesPerSec < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
        this.rate = (this.bytesPerSec / (1024 * 1024)).toFixed(2) + "MB/s"
      } else {                                            //其他转化成GB
        this.rate = (this.bytesPerSec / (1024 * 1024 * 1024)).toFixed(2) + "GB/s"
      }

      if (file.size < 104857600) {
        this.percent = file.percent;
      } else {
        let uploaded = file.type.substring(file.type.indexOf("|") + 1)
        this.percentflag = file.type.substring(file.type.indexOf('|') + 1) * 1000 / 1000 > 0
        if (uploaded > 0) {
          let sjs = Math.round(Math.random() * 3);
          let p = (uploaded * 1000 / 1000) + sjs;
          if (p >= 100) {
            file.type = file.type.replace(uploaded, 99)
            this.percentUp = 99;
          } else if (p < 100) {
            file.type = file.type.replace(uploaded, p)
            this.percentUp = p
          }
        }
      }
    },
    // 获取藏品
    inputUploader(up) {
      this.up = up;
      this.files = up.files;
    },
    upband(id) {
      this.eleId = id
    },
    deleteFile(id, index) {
      let file = this.up.getFile(id);
      var that = this
      let param = {
        fileId: file.fileId
      }
      if (!file.fileId) {
        that.tableData.splice(index, 1);    //删除数组中的该条数据
        that.up.removeFile(file);
        return
      }
      this.$axios(this.api.dyyg.removeFileById, param, "get").then((res) => {
        if (res.status) {
          that.tableData.splice(index, 1);    //删除数组中的该条数据
          that.up.removeFile(file);
          that.refreshList(that.levelId)
          this.$message.success("删除成功")
        } else {
          this.$message.error("删除失败");
        }
      })
    },
    uploadStart() {
      this.up.start();
      this.uploading = true;
    },
    uploadStop() {
      this.up.stop();
      this.uploading = false;
    },
  }
}
</script>

<style scoped>
.treeTb.table >>> .cell {
  text-align: left !important;
}

.el-tabs--border-card >>> .el-tabs__content {
  height: calc(100vh - 282px);
  overflow-y: auto;
}

.ajodfjkadbf {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.el-upload-dragger .el-upload__text {
  padding-top: 60px !important;
  font-size: 12px;
  color: #aaa;
}

.upload-demo >>> .el-upload {
  display: block;
}

.upload-demo >>> .el-upload-dragger {
  margin: 0 auto;
}

.contentBox {
  height: calc(100% - 80px);
  overflow-y: auto;
  overflow-x: hidden;
}

.contentBox .item {
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  box-shadow: 0px 0px 6px #cecece;
  margin: 30px 20px;
  /*padding: 10px 20px 10px 0px;*/
  position: relative;
}

.item >>> .el-button.el-button--text {
  padding: 0;
  font-size: 18px;
}

.contentBox .delBtn {
  position: absolute;
  right: -10px;
  top: -10px;
}

.uploadImg {
  width: 100px;
  padding: 20px 0;
  border-right: 2px solid #efefef;
  margin-right: 30px;
}

.uploadImg img {
  width: 32px;
  height: 32px;
}

.titLine {
  width: 4px;
  height: 15px;
  margin-right: 8px;
}

.uploadBtn {
  border: 2px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  margin: 20px;
  padding: 60px 40px;
  font-size: 12px;
}

.shutu {
  height: calc(100vh - 285px);
  overflow-y: auto;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.vxe-table--render-default.size--small .vxe-body--column.col--ellipsis > .vxe-cell,
.el-table th.el-table__cell > .cell,
.el-table .cell {
  text-align: left !important;
}

/*.mokuaianniu{*/
/*    left: 50%;*/
/*}*/

.imgUpData {
  width: 110px;
}
</style>
